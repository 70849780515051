import React from "react";
/* import { Link } from "react-router-dom"; */
import { Col } from "reactstrap";

const PricingBox = ({ pricings }) => {
  return (
    <React.Fragment>
      {pricings.map((pricing, key) => (
        <Col lg={4} key={key}>
          <div
            className={
              pricing.isActive
                ? "text-center pricing-box bg-white hover-effect price-active"
                : "text-center pricing-box hover-effect"
            }
          >
            <h4 className="text-uppercase">{pricing.title}</h4>
            <h1>${pricing.price}0</h1>
            <h6 className="text-uppercase text-muted">
              Billing Per {pricing.duration}
            </h6>
            <div className="pricing-border"></div>
            <div className="plan-features mt-4">
              {pricing.features.map((feature, key) => (
                <React.Fragment key={key}>
                  {feature.isLeft ? (
                    <p>
                      <b className="text-primary">{feature.value}</b>{" "}
                      {feature.title}
                    </p>
                  ) : (
                    <p key={key}>
                      {feature.title}:{" "}
                      <b className="text-primary">{feature.value}</b>
                    </p>
                  )}
                </React.Fragment>
              ))}
              <p>
                <b className="text-primary">No</b> Hidden Fees
              </p>
            </div>
            {/*             <Link
              to="#"
              className="btn btn-primary waves-effect waves-light mt-4"
            >
              Join Now
            </Link> */}
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default PricingBox;
