import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  /*   Button, */
} from "reactstrap";

import ScrollspyNav from "./scrollSpy";

const NavbarPage = ({ navItems }) => {
  const [state, setState] = useState({
    isOpenMenu: false,
    navClass: "",
  });

  const toggle = () => {
    setState({ isOpenMenu: !state.isOpenMenu });
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > 50) {
      setState({ navClass: "is-sticky" });
    } else {
      setState({ navClass: "" });
    }
  };

  //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  let targetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <div id="navbar" className={state.navClass}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
          <Container>
            <NavbarBrand className="logo" href="/">
              <span className="text-uppercase">ADALIZE: </span>
              <span className="text-lowercase">Amazon Data Analysis</span>
            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>

            <Collapse id="navbarCollapse" isOpen={state.isOpenMenu} navbar>
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="navbar-center" id="mySidenav">
                  {navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink href={"#" + item.idnm}>
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                {/*                 <div className="nav-button ms-auto">
                  <Nav navbar className="navbar-end">
                    <li>
                      <Button
                        color="none"
                        type="button"
                        className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                      >
                        Try it Free
                      </Button>
                    </li>
                  </Nav>
                </div> */}
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavbarPage;
