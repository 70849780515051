import React, { useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

const Process = ({ sectionClass }) => {
  const [state] = useState({
    services1: [
      {
        icon: "pe-7s-graph1",
        title: "Comprehensive Sales Tracking",
        desc: "Track your Amazon sales with precision using Adalize's cutting-edge sales tracking system. Gain real-time insights into your financials with our user-friendly dashboard that simplifies complex data.",
      },
      {
        icon: "pe-7s-users",
        title: "Accounting Optimization",
        desc: "Optimize your accounting with Adalize's smart tools. Streamline your financial workflows and enjoy automated reporting, tailored to the unique needs of your Amazon business.",
      },
      {
        icon: "pe-7s-bell",
        title: "Alerts for Market Fluctuations",
        desc: "Stay ahead of the game with immediate alerts on market changes. Adalize's system keeps you informed of any listing updates, price shifts, and competitive moves.",
      },
    ],
    services2: [
      {
        icon: "pe-7s-wallet",
        title: "Refunds and Inventory Reconciliation",
        desc: "Secure your investments with our comprehensive inventory management and lost inventory refund services. Ensure every product is accounted for with Adalize.",
      },
      {
        icon: "pe-7s-graph3",
        title: "PPC Strategy and Optimizationt",
        desc: "Maximize your ROI with Adalize Group's PPC optimization services. Fine-tune your advertising strategy with our expert analysis and recommendations.",
      },
      {
        icon: "pe-7s-note2",
        title: "Custom Reporting and Insights",
        desc: "Receive detailed, data-driven reports crafted by our specialists at Adalize Group. Get actionable insights that cater to the specific contours of your business landscape.",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className={"section " + sectionClass} id="services">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Our Services"
            desc="Adalize provides you with state-of-the-art tools for sales tracking and accounting management on Amazon. Leverage our advanced technology for precise sales tracking, accounting optimization, and market analysis strategies. With our customized solutions, transform your data into smart decisions and solid profitability."
          />

          <Row className="mt-4 d-flex justify-content-center align-items-center">
            {/* services box */}
            <ServiceBox services={state.services1} />
          </Row>

          <Row className="mt-4 d-flex justify-content-center align-items-center">
            {/* service box */}
            <ServiceBox services={state.services2} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
