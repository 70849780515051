import React, { useEffect, useState } from "react";

function ScrollspyNav(props) {
  const [scrollTargetIds] = useState(props.scrollTargetIds);
  const [activeNavClass] = useState(props.activeNavClass);
  const [scrollDuration] = useState(Number(props.scrollDuration) || 1000);
  const [headerBackground] = useState(
    props.headerBackground === "true" ? true : false
  );

  const homeDefaultLink =
    props.router && props.router === "HashRouter" ? "#/" : "/";
  const hashIdentifier =
    props.router && props.router === "HashRouter" ? "#/#" : "#";

  function easeInOutQuad(current_time, start, change, duration) {
    current_time /= duration / 2;
    if (current_time < 1)
      return (change / 2) * current_time * current_time + start;
    current_time--;
    return (-change / 2) * (current_time * (current_time - 2) - 1) + start;
  }

  function scrollTo(start, to, duration) {
    let change = to - start,
      currentTime = 0,
      increment = 10;

    function animateScroll() {
      currentTime += increment;
      let val = easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }

    animateScroll();
  }

  function getNavLinkElement(sectionID) {
    return document.querySelector(`a[href='${hashIdentifier}${sectionID}']`);
  }

  function getNavToSectionID(navHref) {
    return navHref.includes(hashIdentifier)
      ? navHref.replace(hashIdentifier, "")
      : "";
  }

  function clearOtherNavLinkActiveStyle(excludeSectionID) {
    scrollTargetIds.forEach((sectionID, index) => {
      if (sectionID !== excludeSectionID) {
        getNavLinkElement(sectionID).classList.remove(activeNavClass);
        getNavLinkElement(sectionID).parentNode.classList.remove(
          activeNavClass
        );
      }
    });
  }

  useEffect(() => {
    if (document.querySelector(`a[href='${homeDefaultLink}']`)) {
      document
        .querySelector(`a[href='${homeDefaultLink}']`)
        .addEventListener("click", (event) => {
          event.preventDefault();
          scrollTo(window.scrollY, 0, scrollDuration);
          window.location.hash = "";
        });
    }

    document
      .querySelector("div[data-nav='list']")
      .querySelectorAll("a")
      .forEach((navLink) => {
        navLink.addEventListener("click", (event) => {
          event.preventDefault();
          const sectionID = getNavToSectionID(navLink.getAttribute("href"));

          if (sectionID) {
            const scrollTargetPosition =
              document.getElementById(sectionID).offsetTop -
              (headerBackground
                ? document.querySelector("div[data-nav='list']").scrollHeight
                : 0);
            scrollTo(window.scrollY, scrollTargetPosition, scrollDuration);
          } else {
            scrollTo(window.scrollY, 0, scrollDuration);
          }
        });
      });

    window.addEventListener("scroll", scrollSection, true);

    return () => {
      window.removeEventListener("scroll", scrollSection, true);
    };
  });

  function scrollSection() {
    let scrollSectionOffsetTop;
    scrollTargetIds.forEach((sectionID, index) => {
      scrollSectionOffsetTop =
        document.getElementById(sectionID).offsetTop -
        (headerBackground
          ? document.querySelector("div[data-nav='list']").scrollHeight
          : 0);

      if (
        window.scrollY >= scrollSectionOffsetTop &&
        window.scrollY <
          scrollSectionOffsetTop +
            document.getElementById(sectionID).scrollHeight
      ) {
        getNavLinkElement(sectionID).classList.add(activeNavClass);
        getNavLinkElement(sectionID).parentNode.classList.add(activeNavClass);
        clearOtherNavLinkActiveStyle(sectionID);
      } else {
        getNavLinkElement(sectionID).classList.remove(activeNavClass);
        getNavLinkElement(sectionID).parentNode.classList.remove(
          activeNavClass
        );
      }

      if (
        window.innerHeight + window.pageYOffset >= document.body.scrollHeight &&
        index === scrollTargetIds.length - 1
      ) {
        getNavLinkElement(sectionID).classList.add(activeNavClass);
        getNavLinkElement(sectionID).parentNode.classList.add(activeNavClass);
        clearOtherNavLinkActiveStyle(sectionID);
      }
    });
  }

  return (
    <div data-nav="list" className={props.className}>
      {props.children}
    </div>
  );
}

export default ScrollspyNav;
