import React from "react";
import { Container, Row, Col, NavLink, NavItem } from "reactstrap";
import ScrollspyNav from "../Navbar/scrollSpy";
/* import { Link } from "react-router-dom"; */

const WebsiteDesc = () => {
  return (
    <React.Fragment>
      <section className="section section-lg bg-web-desc">
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="text-white">
                Propel Your Venture with Adalize's Sales Acceleration Suite
              </h2>
              <p className="pt-3 home-desc">
                Gain an edge in the evolving realm of online commerce with no
                hard sell required. Adalize arms you with an array of precision
                analytics and sales tracking tools to navigate the Amazon
                marketplace with finesse. Rise above the noise of data overload
                with our streamlined interface. Our vigilant system ensures that
                your Amazon strategy is always on course for success.
              </p>
              <ScrollspyNav
                scrollTargetIds={"pricing"}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <NavItem className=" btn btn-white mt-4 waves-effect waves-light mb-5">
                  <NavLink href="#pricing" to="#pricing">
                    View Plan & Pricing
                  </NavLink>
                </NavItem>
              </ScrollspyNav>
            </Col>
          </Row>
        </Container>
        <div className="bg-pattern-effect">
          <svg
            width="100%"
            height={43}
            viewBox="0 0 2002 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 9.49999V42L1998.5 40V23H1962L1879 33L1758.5 36L1703 29.5L1304.5 21.5C1285.3 15.5 1183.83 24 1135.5 29L636 35.5C455.2 1.89999 136.667 4.16665 0 9.49999Z"
              fill="white"
              fillOpacity="0.1"
            />
            <path
              opacity="0.1"
              d="M0 33.5V41.5L1997.5 42V27H1972.5C1585.3 -21.8 1403.17 6.66666 1360.5 27C1299.7 13.4 1035.17 6 910.5 4C856.1 -6.8 597.5 8.5 475 17.5L0 33.5Z"
              fill="white"
            />
            <path
              d="M0 31.6689V42.1689L2001.5 41.6689V11.6689L1942.25 18.3143C1910.49 21.8758 1879.21 29.7147 1847.38 32.5593C1799.52 36.8366 1750.41 29.3968 1727 23.6689C1577.8 -8.33114 1367.17 10.3355 1280.5 23.6689C1199.3 40.8689 1126.17 30.8355 1106 23.6689C1023.13 -2.89279 818.252 7.7814 689.669 19.2529C659.224 21.9691 629.271 29.2178 598.77 31.21C559.856 33.7516 520.953 28.0794 502 23.6689C395.2 -8.73115 122.833 15.1689 0 31.6689Z"
              fill="white"
            />
          </svg>
        </div>
      </section>
    </React.Fragment>
  );
};

export default WebsiteDesc;
