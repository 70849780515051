import React, { useState } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Footer from "../../components/Footer/footer";

const Index9 = () => {
  const [state] = useState({
    navItems: [
      { id: 1, idnm: "home", navheading: "Home" },

    ],
    navClass: "",
  });

  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <NavbarPage navItems={state.navItems} navClass={state.navClass} />

      {/* section */}
      <Section />


      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Index9;
