import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Dropdown } from "primereact/dropdown";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";
import axios from "axios";

const Section = () => {
  const [state, setState] = useState({
    isOpen: false,
  });

  const [store, setStore] = useState(null);
  const [selectStore, setSelectStore] = useState(null);
  const [buttonSave, setButtonSave ] = useState(false)
  const [data, setData] = useState();

  const options = {
    name: "Parallax",
    particles: {
        number: {
            value: 100,
            density: {
                enable: true,
            },
        },
        color: {
            value: "#ffffff",
        },
        shape: {
            type: "circle",
        },
        opacity: {
            value: {
                min: 0.1,
                max: 0.5,
            },
            animation: {
                enable: true,
                speed: 3,
                sync: false,
            },
        },
        size: {
            value: {
                min: 1,
                max: 10,
            },
            animation: {
                enable: true,
                speed: 20,
                sync: false,
            },
        },
        links: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
        },
        move: {
            enable: true,
            speed: 2,
        },
    },
    
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "grab",
                parallax: {
                    enable: true,
                    smooth: 10,
                    force: 60,
                },
            },
            onClick: {
                enable: true,
                mode: "push",
            },
        },
        modes: {
            grab: {
                distance: 400,
                links: {
                    opacity: 1,
                },
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 0.8,
            },
            repulse: {
                distance: 200,
            },
            push: {
                quantity: 4,
            },
            remove: {
                quantity: 2,
            },
        },
    },
    background: {
        color: "#0d47a1",
    },
};

  const closeModal = () => {
    setState({ isOpen: false });
  };

  //particales
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: res } = await axios.get(
        "https://elite-inventory-api.vercel.app/norepeatstore"
      );
      let storeData = res.map((item) => item.store);
      setStore(storeData);
    };
    fetchData();
    setData((prev) => ({ ...prev, url: window.location.href }));
  }, []);

  const handleSave = async () => {
    setButtonSave(true)
    try {
      const { data: res, status } = await axios.post(
        "https://elite-inventory-api.vercel.app/save/token",
        data
      );
      if (status !== 200) {
        alert(res.message);
      } else {
        alert("Store registered successfully");
        window.location.href = "/";
      }
    } catch (err) {
      let { data, status } = err.response;
      if (status !== 200) {
        alert(data.message);
      } else {
        alert("Store registered successfully");
        window.location.href = "/";
      }
    }
  };

  return (
    <React.Fragment>
      <section className="section bg-home vh-100" id="home">
        <div className="bg-overlay">
          <Particles id="tsparticles" init={particlesInit} options={options} />
        </div>

        <div className="">
          <div className="">
            <Container className="slidero">
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="text-white text-center"
                >
                  <h1 className="home-title">
                    Last steps to register your store.{" "}
                  </h1>
                  <p className="pt-3 home-desc">
                    To finalize the store registration please enter the name of
                    the store. Then click on the save button
                  </p>
                  <div>
                    {/* input */}
                    <Dropdown
                      options={store}
                      value={selectStore}
                      filter
                      onChange={(e) => {
                        setSelectStore(e.value);
                        setData((prev) => ({ ...prev, store: e.value }));
                      }}
                      placeholder="Select a Store"
                      className="mt-3 mb-3 w-100"
                    />
                  </div>
                  <div>
                    <button
                      onClick={handleSave}
                      type="submit"
                      disabled={buttonSave}
                      className="btn btn-primary btn-rounded mt-3"
                      
                    >
                      <i className="mdi mdi-play"></i> Register Store
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* Render ModalSection Component for Modal */}
        <ModalSection
          channel="vimeo"
          videoId="99025203"
          closeModal={closeModal}
          isOpen={state.isOpen}
        />
      </section>
    </React.Fragment>
  );
};

export default Section;
