import React from "react";
import { Container, Row, Col } from "reactstrap";
/* import { Link } from "react-router-dom"; */

const Social = () => {
  return (
    <React.Fragment>
      <section className="contact-social bg-light">
        <Container>
          <Row className="align-items-center">
            {/*<Col lg={6}>
                             <ul className="list-inline social mt-4">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/EAG.Ecommerce"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </a>
                </li>
                          <li className="list-inline-item">
                  <Link to="#" className="social-icon">
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li> 
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/elite-automation-group-latam/"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon"
                  >
                    <i className="mdi mdi-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCzziQxs_ncaNxLl7BvCGeHw/"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon"
                  >
                    <i className="mdi mdi-youtube"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/eliteautomationgroup/"
                    target="_blank"
                    rel="noreferrer"
                    className="social-icon"
                  >
                    <i className="mdi mdi-instagram"></i>
                  </a>
                </li>
              </ul> 
            </Col>*/}
            <Col
              lg={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col lg={3} className="mt-4">
                <p className="contact-title">
                  <span className="h5">Office Address:</span>
                  <span className="text-muted d-block mt-2">
                    4162 N Jodhpur Ct, Oviedo, FL 32765
                  </span>
                </p>
              </Col>
              <Col lg={3} className="mt-4">
                <p className="contact-title">
                  <span className="h5">Working Hours:</span>
                  <span className="text-muted d-block mt-2">
                    7:00AM To 4:00PM Mon-Fri
                  </span>
                </p>
              </Col>
              <Col lg={3} className="mt-4">
                <p className="contact-title">
                  <i className="pe-7s-call"></i> &nbsp;4072551197
                </p>
              </Col>
              <Col lg={3} className="mt-4 text-end">
                <p className="contact-title d-flex gap-1">
                  <i className="pe-7s-mail-open"></i>&nbsp;
                  <a
                    href="https://go.oncehub.com/ADAlyze"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@adalyzeapp.com
                  </a>
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Social;
