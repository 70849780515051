import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
/* import { Link } from "react-router-dom"; */

//Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";

const Process = () => {
  const [state] = useState({
    processes: [
      {
        icon: "pe-7s-map-2",
        title: "Chart Your Course",
        desc: "Engage with us to outline your ambitions and pinpoint hurdles. Our team is poised to grasp and strategize around your specific needs for Amazon triumph.",
      },
      {
        icon: "pe-7s-notebook",
        title: "Craft Your Strategy",
        desc: "Expect a comprehensive plan sculpted to your business's contours, complete with clear-cut pricing. Your bespoke e-commerce blueprint awaits.",
      },
      {
        icon: "pe-7s-rocket",
        title: "Launch to Success",
        desc: "With your signal, our specialists mobilize, deploying elite strategies and tools to bolster your Amazon venture's growth and efficiency.",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          {/* section title */}
          <SectionTitle
            title="WORK PROCESS"
            desc="At Adalize, precision and tailored strategies are at the core of our philosophy. Our methodical approach ensures transparency and thoroughness at every phase, guiding you through the complexities of Amazon with ease. With us, you'll always be in the know, armed with insights and tools essential for navigating the digital sales frontier."
          />

          <Row>
            <Col lg={6} className="text-center process-left-icon-1">
              <i className="pe-7s-angle-right"></i>
            </Col>
            <Col lg={6} className="text-center process-left-icon-2">
              <i className="pe-7s-angle-right"></i>
            </Col>
          </Row>
          <Row className="mt-5">
            <ProcessBox processes={state.processes} />
            {/*   <div className="text-center mx-auto">
              <Link
                to="#"
                className="btn btn-primary waves-light waves-effect mt-5"
              >
                Get Started <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
