import React from "react";

import Index9 from "./pages/Index9/Index9";
import Index2 from "./pages/Index2/Index2";

const routes = [
  { path: "/registerstore", component: <Index9 /> },
  { path: "/", component: <Index2 /> },
];

export default routes;
