import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

/* import { Link } from "react-router-dom"; */

const items = [
  {
    src: "assets/images/dashboard.png",
    altText: "Dashboard Slide 1",
    caption: "Slide 1",
  },
  {
    src: "assets/images/dashboard2.png",
    altText: "Dashboard Slide 2",
    caption: "Slide 2",
  },
  // Puedes añadir más imágenes aquí
];

const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="img-fluid" />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });
  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="vertical-content">
            <Col lg={5}>
              <div className="features-box">
                <h3>
                  Elevate Your Sales Game with Adalize's Advanced Solutions
                </h3>
                <p className="text-muted web-desc">
                  Navigate the complexities of Amazon with Adalize as your
                  guide. Our platform cuts through the e-commerce clutter,
                  shining a light on the path to strategic decisions that can
                  revolutionize your approach to sales.
                </p>
                <ul className="text-muted list-unstyled mt-4 features-item-list">
                  <li className="">
                    Customized analytics that resonate with the unique contours
                    of your market.
                  </li>
                  <li className="">
                    Essential tools to cement your presence in the online
                    marketplace.
                  </li>
                  <li className="">
                    Unlock comprehensive insights with our robust sales tracking
                    system.
                  </li>
                  <li className="">
                    Reinvent your Amazon business with a click, leveraging our
                    smart automation.
                  </li>
                </ul>
                {/*                 <Link
                  to="#"
                  className="btn btn-primary mt-4 waves-effect waves-light"
                >
                  Learn More <i className="mdi mdi-arrow-right"></i>
                </Link> */}
              </div>
            </Col>
            <Col lg={7}>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
