import React, { useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

const Pricing = () => {
  const [state] = useState({
    pricings: [
      {
        title: "STARTER",
        price: 23.9,
        duration: "MONTH",
        features: [
          { title: "Orders per month", value: "6000" },
          {
            title: "Follow-up emails or review requests per month",
            value: "6000",
          },
          { title: "Additional Seller Accounts", value: "6" },
          { title: "Additional Users", value: "2" },
          { title: "Automated Reporting", value: "6" },
          {
            title: "Manage user access by account, marketplace, and products",
            value: "✘",
          },
          { title: "Live Dashboard", value: "✔" },
          { title: "Alerts for Listing Changes", value: "✔" },
          { title: "PCC Optimization", value: "✔" },
          { title: "Inventory Management", value: "✔" },
          { title: "Refunds for Lost Inventory", value: "✔" },
          { title: "LTV Board", value: "✔" },
        ],
      },
      {
        title: "DELUXE",
        price: 31.9,
        duration: "MONTH",
        features: [
          { title: "Orders per month", value: "15000" },
          {
            title: "Follow-up emails or review requests per month",
            value: "15000",
          },
          { title: "Additional Seller Accounts", value: "8" },
          { title: "Additional Users", value: "4" },
          { title: "Automated Reporting", value: "8" },
          {
            title: "Manage user access by account, marketplace, and products",
            value: "✘",
          },
          { title: "Live Dashboard", value: "✔" },
          { title: "Alerts for Listing Changes", value: "✔" },
          { title: "PCC Optimization", value: "✔" },
          { title: "Inventory Management", value: "✔" },
          { title: "Refunds for Lost Inventory", value: "✔" },
          { title: "LTV Board", value: "✔" },
        ],
      },
      {
        title: "ULTIMATE",
        price: 63.9,
        duration: "MONTH",
        features: [
          { title: "Orders per month", value: "100000" },
          {
            title: "Follow-up emails or review requests per month",
            value: "30000",
          },
          { title: "Additional Seller Accounts", value: "16" },
          { title: "Additional Users", value: "10" },
          { title: "Automated Reporting", value: "16" },
          {
            title: "Manage user access by account, marketplace, and products",
            value: "✔",
          },
          { title: "Live Dashboard", value: "✔" },
          { title: "Alerts for Listing Changes", value: "✔" },
          { title: "PCC Optimization", value: "✔" },
          { title: "Inventory Management", value: "✔" },
          { title: "Refunds for Lost Inventory", value: "✔" },
          { title: "LTV Board", value: "✔" },
        ],
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="section pt-5" id="pricing">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Our Pricing"
            desc="Choose Your Path to Success with Adalize's Pricing Plans."
          />

          <Row className="mt-5">
            {/* pricing box */}
            <PricingBox pricings={state.pricings} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
